import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import './index.css';
import profile from './assets/img/profile.png';
import URLS from "./components/common/constants";

function Login() {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
   axios
      .post(URLS.BASE + URLS.LOGIN, values)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("accessToken", response.data.token);
          if(response.data.rol === 'ADMIN'){
            navigate("/views/home-admin");
          }
        } else {
          alert(response.data.message);
        }
      }).catch((error) => {
        console.log(error);
        if(error.message.includes('401')){
          alert('email y/o contraseña invalidos');
        }else{
          alert(error.message);
        }
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-primary vh-100">
      <div className="bg-white p-3 rounded w-25">
      <div className="text-center mt-2 name">
      <h3>Inicio de Sesi&oacute;n SILP WEB</h3>
      </div>
      <div className="logo">
        <img src={profile} alt="logo" />
      </div>
      <hr />
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="emailId">
              <strong>Correo Electr&oacute;nico:</strong>
            </label>
            <input
              id="emailId"
              type="email"
              placeholder="Ingrese Correo electr&oacute;nico"
              name="email"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              className="form-control rounded-0"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="passwordId">
              <strong>Contrase&ntilde;a:</strong>
            </label>
            <input
              id="passwordId"
              type="password"
              placeholder="Ingrese Contrase&ntilde;a"
              name="password"
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
              className="form-control rounded-0"
              required
            />
          </div>
          <button type="submit" className="btn btn-success w-100 rounded-0">
            <strong>Aceptar</strong>
          </button>
          <hr />
          <Link
            to="/register"
            className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none"
          >
            ¿&Oacute;lvido Contrase&ntilde;a?
          </Link>
        </form>
      </div>
    </div>
  );
}

export default Login;
