import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from '../../components/common/Footer';
import HeaderAdmin from './HeaderAdmin';
import './admin.css';
import LogoHome from '../../assets/img/tip.png';

function HomeAdmin() {
  return (
    <div className='Container-fluid page-container'>
    <HeaderAdmin/>
    <div className='Container-fluid content-wrap'>
    <figure className='figureLogo'>
      <img className='logoHome' src={LogoHome} alt='home'/>
      </figure>
    </div>
      <Footer/>
    </div>
  )
}

export default HomeAdmin;
