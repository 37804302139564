import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './common.css';

function Footer() {
  return (
    <div className="footer bg-primary">
      <p>&copy; A&A Tecnologias S.A.S</p>
    </div>
  )
}

export default Footer;
