const URLS = {
    'BASE' : 'https://dev.aatecnologias.com/propinas/v1/api/',
    'LOGIN' : 'login',
    'USUARIOS' : 'usuarios',
    'TIPO_DOC' : 'tipodoc',
    'ROLES' : 'roles',
    'ESTADO' : 'estado'
};

Object.freeze(URLS);

export default URLS;