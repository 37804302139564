import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from './login';
import ReportesAdmin from './role/admin/ReportesAdmin';
import HomeAdmin from './role/admin/HomeAdmin';
import UsuariosAdmin from './role/admin/UsuariosAdmin';
import NuevoUsuarioAdmin from './role/admin/NuevoUsuarioAdmin';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/logout' element={<Login/>}></Route>
        <Route path='/views/home-admin' element={<HomeAdmin/>}></Route>
        <Route path='/views/home-admin/reportes' element={<ReportesAdmin/>}></Route>
        <Route path='/views/home-admin/usuarios' element={<UsuariosAdmin/>}></Route>
        <Route path='/views/home-admin/usuarios/nuevo' element={<NuevoUsuarioAdmin/>}></Route>
        </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
