import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../components/common/Footer";
import HeaderAdmin from "./HeaderAdmin";
import "./admin.css";

function ReportesAdmin() {
  return (
    <div className="page-container">
      <HeaderAdmin />
      <div className="content-wrap">
        <div className="container-fluid justify-content-center align-items-center p-3 rounded w-50">
          <div className="card">
            <div className="card-header">
              <span>
                <strong>REPORTES</strong>
              </span>
            </div>
            <div className="card-body">
              {/** aca van los controles del formulario */}
              <div className="mb-3">
                <label for="rptTipoId">
                  <strong>Tipo Reporte:</strong>
                </label>
                <select
                  id="rptTipoId"
                  className="form-select rounded-0"
                  required
                >
                  <option value="">
                    <span>Seleccione</span>
                  </option>
                </select>
              </div>
              <hr />
              <div className="mb-3">
                <label for="periodoId">
                  <strong>Periodo:</strong>
                </label>
                <select
                  id="periodoId"
                  className="form-select rounded-0"
                  required
                >
                  <option value="">
                    <span>Seleccione</span>
                  </option>
                </select>
              </div>
              <hr />
              <div className="mb-3">
                <label for="departamentoId">
                  <strong>Departamento:</strong>
                </label>
                <select
                  id="departamentoId"
                  className="form-select rounded-0"
                  required
                >
                  <option value="">
                    <span>Seleccione</span>
                  </option>
                </select>
              </div>
              <hr />
              <div className="mb-3">
                <label for="tipoBeneficiarioId">
                  <strong>Tipo Beneficiario:</strong>
                </label>
                <select
                  id="tipoBeneficiarioId"
                  className="form-select rounded-0"
                  required
                >
                  <option value="">
                    <span>Seleccione</span>
                  </option>
                </select>
              </div>
              <hr />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  checked
                />
                <label class="form-check-label" for="inlineRadio1">
                  <span>Excel</span>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                />
                <label className="form-check-label" for="inlineRadio2">
                  <span>Pdf</span>
                </label>
              </div>
              <hr />
              <button type="submit" className="btn btn-primary w-100 rounded-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-file-earmark-break"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 4.5V9h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v7H2V2a2 2 0 0 1 2-2h5.5zM13 12h1v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z" />
                </svg>
                <strong>Generar</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ReportesAdmin;
