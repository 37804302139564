import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-icons";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Footer from "../../components/common/Footer";
import HeaderAdmin from "./HeaderAdmin";
import "./admin.css";
import { PrimeReactProvider } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import URLS from "../../components/common/constants";
import axios from "axios";
import ROLES from "../../components/common/roles";
import ESTADOS from "../../components/common/estados";
import { Tag } from "primereact/tag";

function UsuariosAdmin() {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);

  /**se carga la informacion en la tabla */
  useEffect(() => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    axios
      .get(URLS.BASE + URLS.USUARIOS, { headers })
      .then((response) => {
        //console.log(response.data);
        setUsuarios(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("401")) {
          alert("Unauthorized access");
          localStorage.removeItem("accessToken");
          navigate("/login");
        } else {
          alert(error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**Botones de accion para tabla de administracion de usuarios */
  const buttonsAction = (usuarios) => {
    return (
      <div>
        <Link className="btn btn-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-gear"
            viewBox="0 0 16 16"
          >
            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
          </svg>
          <span>Editar</span>
        </Link>
        <Link className="btn btn-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-dash"
            viewBox="0 0 16 16"
          >
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
            <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
          </svg>
          <span>Eliminar</span>
        </Link>
      </div>
    );
  };

  const getRolDescription = (usuarios) => {
    switch (usuarios.idRole) {
      case 1:
        return ROLES.ADMIN;
      case 2:
        return ROLES.RECURSOS_HUMANOS;
      case 3:
        return ROLES.CONTABILIDAD;
      case 4:
        return ROLES.NOVEDADES;
      default:
        return "";
    }
  };

  const getEstadoDescription = (usuarios) => {
    switch (usuarios.idEstado) {
      case 1:
        return ESTADOS.ACTIVO;
      case 2:
        return ESTADOS.BLOQUEADO;
      case 3:
        return ESTADOS.INACTIVO;
      default:
        return "";
    }
  };

  const getSeverity = (usuarios) => {
    switch (usuarios.idEstado) {
      case 1:
        return "success";
      case 2:
        return "danger";
      case 3:
        return "warning";
      default:
        return null;
    }
  };

  const estadoUsuario = (usuarios) => {
    return <Tag value={getEstadoDescription(usuarios)} severity={getSeverity(usuarios)}></Tag>;
};

  /**componente que se pinta */
  return (
    <div className="page-container">
      <HeaderAdmin />
      <div className="content-wrap">
        <div className="card">
          <div className="card-header">
            <span>
              <strong>LISTADO USUARIOS</strong>
            </span>
          </div>
          <div className="card-body">
            <div className="container-fluid buttonsActions">
              <Link className="btn btn-primary"
              to="/views/home-admin/usuarios/nuevo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-add"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                  <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                </svg>
                <span>Agregar Usuario</span>
              </Link>
            </div>
            <hr />
            <PrimeReactProvider>
              <DataTable
                value={usuarios}
                sortMode="multiple"
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="idTipoDoc" sortable header="Tipo Doc"></Column>
                <Column
                  field="documento"
                  sortable
                  header="Identificaci&oacute;n"
                ></Column>
                <Column field="nombres" sortable header="Nombres"></Column>
                <Column field="apellidos" sortable header="Apellidos"></Column>
                <Column field="correo" sortable header="Correo"></Column>
                <Column body={getRolDescription} sortable header="Rol"></Column>
                <Column
                  body={estadoUsuario}
                  sortable
                  header="Estado"
                ></Column>
                <Column body={buttonsAction} header="Opciones"></Column>
              </DataTable>
            </PrimeReactProvider>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UsuariosAdmin;
