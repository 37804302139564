import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-icons";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Footer from "../../components/common/Footer";
import HeaderAdmin from "./HeaderAdmin";
import "./admin.css";
import { Dropdown } from "primereact/dropdown";
import { Link,useNavigate } from "react-router-dom";
import URLS from "../../components/common/constants";
import axios from "axios";

function NuevoUsuarioAdmin() {
  const navigate = useNavigate();
  const [selectedTipoDoc, setSelectedTipoDoc] = useState(null);
  const [selectedRol, setSelectedRol] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedEstado, setSelectedstado] = useState(null);
  const [estados, setEstados] = useState([]);
  const [tipoDocs, setTiposDocs] = useState([]);
  const [documento, setDocumento] = useState("");
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [clave, setClave] = useState("");
  //obtener tipo doc
  useEffect(() => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    axios
      .get(URLS.BASE + URLS.TIPO_DOC, { headers })
      .then((response) => {
        //console.log(response.data);
        setTiposDocs(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("401")) {
          alert("Unauthorized access retrieve TipoDoc");
          localStorage.removeItem("accessToken");
          navigate("/login");
        } else {
          alert(error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoDocs]);

  //obtener roles
  useEffect(() => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    axios
      .get(URLS.BASE + URLS.ROLES, { headers })
      .then((response) => {
        //console.log(response.data);
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("401")) {
          alert("Unauthorized access retrieve Roles");
          localStorage.removeItem("accessToken");
          navigate("/login");
        } else {
          alert(error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  //obtener estadis
  useEffect(() => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    axios
      .get(URLS.BASE + URLS.ESTADO, { headers })
      .then((response) => {
        //console.log(response.data);
        setEstados(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes("401")) {
          alert("Unauthorized access retrieve Estados");
          localStorage.removeItem("accessToken");
          navigate("/login");
        } else {
          alert(error.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estados]);

  //guardar el usuario
  const handleSubmit = (event) => {
    event.preventDefault();
  const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
  };
  const values = {
    correo : email,
    clave : clave,
    idTipoDoc : selectedTipoDoc.idTipoDoc,
    documento : documento,
    nombres : nombres,
    apellidos : apellidos,
    idRole : selectedRol.idRole,
    telefono : telefono,
    celular : celular,
    idEstado : selectedEstado.idEstado,
    bloqueado : 0,
    reseteado : 0,
    sesionReseteo : ""
  };
   axios
      .post(URLS.BASE + URLS.USUARIOS, values,{headers})
      .then((response) => {
        alert(response.data.message);
        navigate("/views/home-admin/usuarios");
      }).catch((error) => {
        console.log(error);
        if(error.message.includes('401')){
          alert('Unauthorized access, unable to save usuario');
        }else{
          alert(error.message);
        }
      });
  };

  return (
    <div className="page-container">
      <HeaderAdmin />
      <div className="content-wrap">
        <div className="container-fluid justify-content-center align-items-center p-3 rounded w-50">
          <div className="card">
            <div className="card-header">
              <span>
                <strong>NUEVO USUARIO</strong>
              </span>
            </div>
            <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="TipoDocId">
                  <strong>Tipo Documento:</strong>
                </label>
                <Dropdown
                  value={selectedTipoDoc}
                  onChange={(e) => setSelectedTipoDoc(e.value)}
                  options={tipoDocs}
                  optionLabel="tipoDoc"
                  placeholder="SELECCIONE"
                  style={{ width: "100%" }}
                  id="TipoDocId"
                  checkmark={true}
                  highlightOnSelect={false}
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="documentoId">
                  <strong>Documento:</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={documento}
                  id="documentoId"
                  onChange={(e) => setDocumento(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Digite documento"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="nombresId">
                  <strong>Nombres:</strong>
                </label>
                <input
                  type="text"
                  id="nombresId"
                  className="form-control"
                  value={nombres}
                  onChange={(e) => setNombres(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Digite Nombres"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="apellidosId">
                  <strong>Apellidos:</strong>
                </label>
                <input
                  type="text"
                  id="apellidosId"
                  className="form-control"
                  value={apellidos}
                  onChange={(e) => setApellidos(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Digite Apellidos"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="telefonoId">
                  <strong>Tel&eacute;fono:</strong>
                </label>
                <input
                  type="phone"
                  id="telefonoId"
                  className="form-control"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  style={{ width: "100%" }}
                  mask="(+999) 999-9999999"
                  placeholder="(+999) 999-9999999"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="celularId">
                  <strong>Celular:</strong>
                </label>
                <input
                  type="phone"
                  id="celularId"
                  className="form-control"
                  value={celular}
                  onChange={(e) => setCelular(e.target.value)}
                  style={{ width: "100%" }}
                  mask="(+999) 999-9999999"
                  placeholder="(+999) 999-9999999"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="emailId">
                  <strong>Correo:</strong>
                </label>
                <input
                  type="email"
                  id="emailId"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="correo@dominio.com"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="claveId">
                  <strong>Clave:</strong>
                </label>
                <input
                  type="password"
                  id="claveId"
                  className="form-control"
                  value={clave}
                  onChange={(e) => setClave(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Digite Clave"
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="rolId">
                  <strong>Rol:</strong>
                </label>
                <Dropdown
                  value={selectedRol}
                  onChange={(e) => setSelectedRol(e.value)}
                  options={roles}
                  optionLabel="role"
                  placeholder="SELECCIONE"
                  style={{ width: "100%" }}
                  id="rolId"
                  checkmark={true}
                  highlightOnSelect={false}
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <label htmlFor="estadoId">
                  <strong>Estado:</strong>
                </label>
                <Dropdown
                  value={selectedEstado}
                  onChange={(e) => setSelectedstado(e.value)}
                  options={estados}
                  optionLabel="estado"
                  placeholder="SELECCIONE"
                  style={{ width: "100%" }}
                  id="estadoId"
                  checkmark={true}
                  highlightOnSelect={false}
                  required
                />
              </div>
              <hr />
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn btn-primary w-100 rounded-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z" />
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                  </svg>
                  <strong>Guardar</strong>
                </button>
                <hr/>
                <Link
                  className="btn btn-primary w-100 rounded-0"
                  to="/views/home-admin/usuarios"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                  <strong>Regresar</strong>
                </Link>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default NuevoUsuarioAdmin;
